<template>
  <div>
    <div class="router-view-container">
      <!-- <div class="mb-4">
        <vs-button to="/admin/setting" border size="large">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
      </div> -->
      <main class="space-y-4">
        <h1 class="text-center">為您的品牌選擇合適的解決方案</h1>
        <div class="w-max mx-auto flex items-center space-x-4 font-medium">
          <span class="cursor-pointer" @click="planSwitch = false">月繳費用</span>
          <vs-switch v-model="planSwitch" color="success"> </vs-switch>
          <span class="cursor-pointer" @click="planSwitch = true">年繳費用</span>
        </div>
        <!-- plans -->
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div class="box" v-for="item in planData" :key="item.pno">
            <div class="flex flex-col items-start justify-center space-y-6">
              <h1 class="font-bold text-xl">{{ item.pname }}</h1>

              <p class="font-bold text-xl">{{ item.price }}</p>
              <p class="font-bold text-xl">{{ item.sprice }}</p>
              <vs-button color="success" class="w-full h-10">選擇此方案</vs-button>
              <p v-html="item.descs"></p>
            </div>
          </div>
        </div>

        <p>比較各方案功能</p>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plans',
  data() {
    return {
      planData: [],
      planSwitch: false,
    }
  },
  created() {
    this.getPlanData()
  },
  methods: {
    // 4.2.2.1 讀方案費用資料
    getPlanData() {
      this.$axios({
        url: 'front/set/basic/getPlanData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.planData = res.data.Data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
  },
}
</script>

<style scoped>
.box {
  @apply bg-white rounded-xl p-4 hover:shadow-xl transition-shadow duration-500;
}

.price {
}
</style>
